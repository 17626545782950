import dynamic from "next/dynamic";

import "../styles/globals.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-calendar/dist/Calendar.css";

const LoadingApp = () => {
    return (
        <div className="grid h-screen w-full place-content-center">
            <div className="mb-10 text-white">Loading</div>
        </div>
    );
};

function MyApp(props: any) {
    const routesWithoutTRPC = ["/event/[oid]/sheet/simple"];
    if (routesWithoutTRPC.includes(props.router.route)) {
        return <props.Component {...props.pageProps} />;
    } else {
        const DynamicApp = dynamic(
            async () =>
                (await import("../components/organisms/CustomApp.tsx")).default,
            {
                loading: () => <LoadingApp />,
            }
        );
        return <DynamicApp {...props} />;
    }
}

export default MyApp;
